import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { getEndpoint } from '../utils';


export default function LandingPage() {

  const { currentUser, loading } = useUser();
  const [userData, setUserData] = useState(null);
  const username = currentUser?.email || currentUser?.uid;
  
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // This function checks the width of the screen and updates the isMobile state
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // 768px is typically the width for mobile devices
    }

    // Call the function on component mount
    handleResize();

    // Add event listener to listen for screen resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


// @chatGPT -- use these images 
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_stranger_things.png?alt=media&token=83562d36-c32f-4739-9e62-7d7519ab7554
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_breaking.png?alt=media&token=d21fb1f9-4c52-4530-bb0d-fde02cab61a1
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_epstein.png?alt=media&token=7ab1e3df-4501-4236-a1b6-671108467aa1
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_bridgerton.png?alt=media&token=cfc38d03-8374-409a-ab36-754b68cde7d4
  const trackEvent = async (eventName, eventData) => {
    const data = {
      user: username,
      event_name: eventName,
      manufacturer: 'Web',      
      ...eventData,
    };

    fetch(`${getEndpoint()}/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleShowClick = (showId) => {
    trackEvent('start-show', { firstEpisode: showId });
    navigate(`/infinite-show/${showId}`);
  };

return (
  <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100">
    <header className="text-center mb-2 px-4 py-12 relative">
      <div className="bg-purple-700 text-white py-4 px-2 rounded-lg shadow-lg mb-6 mx-auto w-full md:w-1/2">
        <p className="text-lg font-semibold mb-4">NEW: Eggnog Infinite -- your favorite shows, forever</p>
        <div className="flex justify-center space-x-4">
          <div className="w-1/6 block rounded-lg shadow-lg overflow-hidden cursor-pointer relative hover:shadow-blue-800 hover:shadow-lg transition-shadow duration-300" onClick={() => handleShowClick('a68dae60-3a7e-4400-93cd-bd09e57814c3')}>
            <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_stranger_things.png?alt=media&token=83562d36-c32f-4739-9e62-7d7519ab7554" alt="Stranger Things" className="rounded-lg shadow-lg" />
          </div>
          <div className="w-1/6 block rounded-lg shadow-lg overflow-hidden cursor-pointer relative hover:shadow-blue-800 hover:shadow-lg transition-shadow duration-300" onClick={() => handleShowClick('191ab1ee-026a-4439-8471-802f4344cfbf')}>
            <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_breaking.png?alt=media&token=d21fb1f9-4c52-4530-bb0d-fde02cab61a1" alt="Breaking Bad" className="rounded-lg shadow-lg" />
          </div>
          <div className="w-1/6 block rounded-lg shadow-lg overflow-hidden cursor-pointer relative hover:shadow-blue-800 hover:shadow-lg transition-shadow duration-300" onClick={() => handleShowClick('c07b2004-0bfe-4e2c-983d-9c5f317751ae')}>
            <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_epstein.png?alt=media&token=7ab1e3df-4501-4236-a1b6-671108467aa1" alt="Epstein's Escape" className="rounded-lg shadow-lg" />
          </div>
          <div className="w-1/6 block rounded-lg shadow-lg overflow-hidden cursor-pointer relative hover:shadow-blue-800 hover:shadow-lg transition-shadow duration-300" onClick={() => handleShowClick('94b0b59f-6889-408a-a31a-42a42d55eb73')}>
            <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_bridgerton.png?alt=media&token=cfc38d03-8374-409a-ab36-754b68cde7d4" alt="Bridgerton" className="rounded-lg shadow-lg" />
          </div>
        </div>
        <div className="mt-4">
          <a 
            href="https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
            <img 
              src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" 
              alt="Download on the App Store" 
              className="h-12 transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer"
            />
          </a>
        </div>
      </div>

      <h1 className="text-5xl md:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 to-red-400 leading-none mb-6 py-2">Eggnog</h1>
      <p className="text-xl md:text-2xl font-light text-gray-300 max-w-2xl mx-auto leading-relaxed">Create AI videos with consistent characters</p>
    </header>
    
    <main className="flex-grow">
      <div className="container mx-auto px-4">
        <div className='flex items-center justify-center p-2 mb-8'>
          <a href="/playground" target="_blank" rel="noopener noreferrer">
            <button className="bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-white font-semibold py-3 px-8 rounded-lg transition-all duration-300 ease-in-out shadow-lg">Get started for free</button>
          </a>
        </div>
        <section className="bg-gray-800 bg-opacity-50 p-8 rounded-lg shadow-lg backdrop-filter backdrop-blur-md border border-gray-700">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Create Characters</h3>
                  <p className="text-gray-300 text-center">Craft unique faces and outfits</p>
                  <div className="grid grid-cols-2 gap-4">
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/landingpage_0.jpg?alt=media&token=f037295f-691c-4722-8311-2ddf9dfa2e6c" alt="Character 1" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/landingpage_1.jpg?alt=media&token=35b49c98-eecb-4600-9f60-78161abd1dfa" alt="Character 2" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/landingpage_2.jpg?alt=media&token=15e888d3-77c6-47f6-8b15-16cf60f6736e" alt="Character 4" className="w-28 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/landingpage_3.jpg?alt=media&token=b6720a44-4a16-49ec-a84a-fb347ffbda0f" alt="Character 3" className="w-28 h-28 rounded-lg"/>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Storyboard</h3>
                  <p className="text-gray-300 text-center">Drop in your characters</p>
                  <div className="flex flex-col items-center space-y-4">
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1709181066%20(1).jpg?alt=media&token=db4d709d-c5aa-4c9b-a104-ac3a20a41cf1" alt="Storyboarding 1" className="w-56 h-28 rounded-lg"/>
                    <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/1709181031%20(1).jpg?alt=media&token=2c89c818-825c-4914-8d43-e61f91aa30c0" alt="Storyboarding 2" className="w-56 h-28 rounded-lg"/>
                  </div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold text-purple-300 text-center">Generate videos</h3>
                  <p className="text-gray-300 text-center">Animate your scenes</p>
                  <div className="flex flex-col items-center">
                    <img 
                      src="https://replicate.delivery/pbxt/fxba8zuuHywHU6LfcmtjXhVf4pco8LUijmBL8bHpdBZO572kA/video.gif" 
                      alt="Animated scene" 
                      className="w-60 max-w-xs rounded-lg" 
                    />
                  </div>
                </div>
              </div>  
          </div>
        </section>
      </div>
    </main>
    <footer className="text-center text-gray-400 py-4">
      <p>© 2024 Eggnog. All rights reserved.</p>
      <a href="/privacy-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">Privacy Policy</a> | <a href="/eula-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">EULA</a>
    </footer>
  </div>
);

}
