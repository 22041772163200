import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../Firebase.js';
import { doc, onSnapshot, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { X } from 'lucide-react';
import axios from 'axios';

const EpisodeTimeline = () => {
    const { episodeId } = useParams();
    const [episode, setEpisode] = useState(null);
    const [selectedVideos, setSelectedVideos] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [speedUp, setSpeedUp] = useState(1.3);
    const [transitionTime, setTransitionTime] = useState(0.3);
    const [combining, setCombining] = useState(false);
    const [publishing, setPublishing] = useState(false);
    const navigate = useNavigate();
  
    const LoadingSpinner = () => (
      <div className="flex justify-center items-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  
    const isAuthorizedUser = (email) => {
      const authorizedEmails = ['smgplank@gmail.com', 'jitsvm@gmail.com'];
      return authorizedEmails.includes(email);
    };
  
    useEffect(() => {
      const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser);
        if (currentUser && isAuthorizedUser(currentUser.email)) {
          setLoading(true);
          const episodeRef = doc(db, 'text-episodes', episodeId);
          const unsubscribeSnapshot = onSnapshot(
            episodeRef,
            (doc) => {
              if (doc.exists()) {
                const episodeData = { id: doc.id, ...doc.data() };
                setEpisode(episodeData);
                
                const initialSelected = {};
                episodeData.processed_shots.forEach((shot, index) => {
                  if (shot.video_dialogue_urls) {
                    const videos = Object.entries(shot.video_dialogue_urls)
                      .filter(([, data]) => data.status === 'COMPLETED')
                      .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp));
                    
                    if (videos.length > 0) {
                      initialSelected[index] = videos[0][0];
                    }
                  }
                });
                setSelectedVideos(initialSelected);
                setLoading(false);
              }
            },
            (err) => {
              console.error("Error fetching episode:", err);
              setError("Failed to fetch episode. Please try again.");
              setLoading(false);
            }
          );
  
          return () => unsubscribeSnapshot();
        } else {
          setLoading(false);
          setError("Access denied. You are not authorized to view this page.");
        }
      });
  
      return () => unsubscribeAuth();
    }, [episodeId]);
  
    // Rest of the component code remains the same
    const removeFromTimeline = (shotIndex) => {
      setSelectedVideos(prev => {
        const newSelected = { ...prev };
        delete newSelected[shotIndex];
        return newSelected;
      });
    };

  const addToTimeline = (shotIndex, videoKey) => {
    setSelectedVideos(prev => ({
      ...prev,
      [shotIndex]: videoKey
    }));
  };

  const handleCombine = async () => {
    const newKey = Date.now().toString();
    try {
      setCombining(true);
      
      // Create timestamp for the combination
      const currentTimestamp = new Date().toISOString();
  
      // Get the episode document reference
      const episodeRef = doc(db, 'text-episodes', episodeId);
      const episodeDoc = await getDoc(episodeRef);
  
      if (episodeDoc.exists()) {
        // Create the processing record
        const combinedVideos = episodeDoc.data().combined_videos || {};
        combinedVideos[newKey] = {
          status: 'PROCESSING',
          timestamp: currentTimestamp
        };
  
        // Update Firestore with the processing status
        await updateDoc(episodeRef, {
          combined_videos: combinedVideos
        });
  
        // Get the ordered URLs for the API call
        const orderedUrls = episode.processed_shots.map((shot, index) => {
          const selectedKey = selectedVideos[index];
          return selectedKey && shot.video_dialogue_urls?.[selectedKey]?.status === 'COMPLETED'
            ? shot.video_dialogue_urls[selectedKey].url
            : null;
        });
  
        // Make the API call
        const response = await axios.post('http://localhost:2999/combine-videos-for-episode', {
          episodeId,
          newKey,
          videoUrls: orderedUrls,
          speedUp,
          transitionTime
        });
  
        console.log('Combination initiated:', response.data);
      } else {
        throw new Error("Episode document not found");
      }
    } catch (error) {
      console.error('Error combining videos:', error);
      alert('Failed to combine videos. Please try again.');
  
      // If there's an error, try to clean up the processing record
      try {
        const episodeRef = doc(db, 'text-episodes', episodeId);
        const episodeDoc = await getDoc(episodeRef);
  
        if (episodeDoc.exists()) {
          const combinedVideos = episodeDoc.data().combined_videos || {};
          if (combinedVideos[newKey]) {
            delete combinedVideos[newKey];
            await updateDoc(episodeRef, {
              combined_videos: combinedVideos
            });
          }
        }
      } catch (cleanupError) {
        console.error('Error cleaning up processing record:', cleanupError);
      }
    } finally {
      setCombining(false);
    }
  };

  const downloadTranscript = async (url) => {
        try {
            const response = await axios.post(
                'http://localhost:2999/download-transcript',
                { url },
                { responseType: 'blob' }
            );
            
            // Get filename from Content-Disposition header
            const contentDisposition = response.headers['content-disposition'];
            const filename = contentDisposition
                ? contentDisposition.split('filename=')[1].replace(/["']/g, '')
                : `transcript_${Date.now()}.srt`;
            
            const blob = new Blob([response.data], { type: 'text/srt' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Download failed:', error);
            alert('Failed to download transcript. Please try again.');
        }
    };

    const handlePublish = async (videoData) => {
        if (!videoData || videoData.status !== 'COMPLETED') {
            alert('Please wait for video processing to complete before publishing.');
            return;
        }

        try {
            setPublishing(true);
            const episodeRef = doc(db, 'episodes', episodeId);
            const textEpisodeDoc = await getDoc(doc(db, 'text-episodes', episodeId));

            if (!textEpisodeDoc.exists()) {
                throw new Error('Text episode not found');
            }

            const textEpisodeData = textEpisodeDoc.data();
            
            // Create the published episode document
            await setDoc(episodeRef, {
                ...textEpisodeData, // Copy all existing fields
                episode_url: videoData.url,
                watermarked_episode_url: videoData.url, // For now, using same URL
                episode_start_screen_url: '', // Left blank as requested
            });

        } catch (error) {
            console.error('Error publishing episode:', error);
            alert('Failed to publish episode. Please try again.');
        } finally {
            setPublishing(false);
        }
    };

    const renderVideo = (videoData, isCombined = false) => {
        return (
            <div className="space-y-2">
                <div className="relative w-full pb-[177.78%]">
                    {videoData.status === 'PROCESSING' ? (
                        <div className="absolute inset-0 flex items-center justify-center bg-gray-200 rounded-lg">
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <video 
                            src={videoData.url}
                            controls
                            className="absolute inset-0 w-full h-full object-contain rounded-lg"
                            style={{ backgroundColor: '#000' }}
                        >
                            Your browser does not support the video tag.
                        </video>
                    )}
                </div>
                {videoData.srt_url && (
                    <button
                        onClick={() => downloadTranscript(videoData.srt_url)}
                        className="w-full px-4 py-2 bg-gray-500 hover:bg-gray-600 text-white rounded-lg transition-colors"
                    >
                        Download Transcript
                    </button>
                )}
                {isCombined && videoData.status === 'COMPLETED' && (
                    <button
                        onClick={() => handlePublish(videoData)}
                        disabled={publishing}
                        className={`w-full px-4 py-2 text-white rounded-lg transition-colors ${
                            publishing
                                ? 'bg-gray-400 cursor-not-allowed'
                                : 'bg-purple-500 hover:bg-purple-600'
                        }`}
                    >
                        {publishing ? 'Publishing...' : 'Publish Episode'}
                    </button>
                )}
            </div>
        );
    };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!user || !isAuthorizedUser(user.email)) {
    return <div className="p-4">Access denied. You are not authorized to view this page.</div>;
  }

  if (!episode) {
    return <div className="p-4">No episode found</div>;
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!episode) {
    return <div>No episode found</div>;
  }

  return (
    <div className="container mx-auto p-4 space-y-8">
      <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-bold">Episode Timeline</h1>
            <button
            onClick={() => navigate(`/text-episode-viewer/${episodeId}`)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
            Back to Editor
            </button>
      </div>
      {/* Timeline Composer */}
      <div className="bg-white rounded-lg shadow p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Timeline Composer</h2>
          <button
            onClick={handleCombine}
            disabled={combining}
            className={`px-4 py-2 rounded-lg text-white transition-colors ${
              combining
                ? 'bg-gray-400 cursor-not-allowed'
                : 'bg-green-500 hover:bg-green-600'
            }`}
          >
            {combining ? 'Combining...' : 'Combine'}
          </button>
        </div>

        {/* Control Sliders */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Speed Up: {speedUp.toFixed(1)}x
            </label>
            <input
              type="range"
              min="0.5"
              max="2.0"
              step="0.1"
              value={speedUp}
              onChange={(e) => setSpeedUp(parseFloat(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
          </div>
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Transition Time: {transitionTime.toFixed(1)}s
            </label>
            <input
              type="range"
              min="0"
              max="1.0"
              step="0.1"
              value={transitionTime}
              onChange={(e) => setTransitionTime(parseFloat(e.target.value))}
              className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
            />
          </div>
        </div>

        {/* Video Timeline */}
        <div className="overflow-x-auto">
          <div className="flex gap-4 pb-4 min-w-min">
            {episode.processed_shots.map((shot, index) => {
              const selectedKey = selectedVideos[index];
              const videoData = selectedKey && shot.video_dialogue_urls?.[selectedKey];
              
              return (
                <div key={index} className="relative flex-none" style={{ width: '170px' }}>
                  {videoData?.status === 'COMPLETED' ? (
                    <>
                      <button 
                        onClick={() => removeFromTimeline(index)}
                        className="absolute top-2 right-2 z-10 bg-black bg-opacity-50 rounded-full p-1 hover:bg-opacity-75 transition-colors"
                      >
                        <X className="w-4 h-4 text-white" />
                      </button>
                      {renderVideo(videoData)}
                    </>
                  ) : (
                    <div 
                      className="w-full h-80 bg-gray-100 rounded-lg flex items-center justify-center text-gray-400" 
                      style={{ aspectRatio: '9/16' }}
                    >
                      No video selected
                    </div>
                  )}
                  <div className="mt-2 text-sm text-gray-600 text-center">
                    Shot {index + 1}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {/* Combined Videos Section */}
      {episode.combined_videos && Object.keys(episode.combined_videos).length > 0 && (
        <div className="bg-white rounded-lg shadow p-4">
            <h2 className="text-xl font-bold mb-4">Combined Videos</h2>
            <div className="flex gap-4 overflow-x-auto pb-4">
            {Object.entries(episode.combined_videos)
                .filter(([, data]) => data.status === 'COMPLETED' || data.status === 'PROCESSING')
                .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp))
                .map(([key, data]) => (
                <div key={key} className="flex-none" style={{ width: '170px' }}>
                    {renderVideo(data, true)} {/* Pass true to indicate this is a combined video */}
                </div>
                ))}
            </div>
        </div>
    )}

      {/* Video Library */}
      <div className="bg-white rounded-lg shadow p-4">
        <h2 className="text-xl font-bold mb-4">Video Library</h2>
        <div className="space-y-8">
            {episode.processed_shots.map((shot, shotIndex) => {
            if (!shot.video_dialogue_urls) return null;

            return (
                <div key={shotIndex} className="border-b pb-8">
                <h3 className="font-semibold mb-4">Shot {shotIndex + 1}</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                    {Object.entries(shot.video_dialogue_urls)
                    .filter(([, data]) => data.status === 'COMPLETED')
                    .sort(([, a], [, b]) => new Date(b.timestamp) - new Date(a.timestamp))
                    .map(([key, data]) => (
                        <div key={key} className="space-y-2" style={{ width: '170px' }}>
                        {renderVideo(data, false)} {/* Changed to false for Video Library */}
                        <button
                            onClick={() => addToTimeline(shotIndex, key)}
                            className={`w-full px-4 py-2 rounded-lg transition-colors ${
                            selectedVideos[shotIndex] === key
                                ? 'bg-green-500 text-white'
                                : 'bg-blue-500 hover:bg-blue-600 text-white'
                            }`}
                        >
                            {selectedVideos[shotIndex] === key ? 'Selected' : 'Add to Timeline'}
                        </button>
                        </div>
                    ))}
                </div>
                </div>
            );
            })}
        </div>
        </div>
    </div>
  );
};

export default EpisodeTimeline;