import React from 'react';
import { useUser } from '../contexts/UserContext';
import DropdownMenu from './DropdownMenu.jsx';
import { useParams } from 'react-router-dom';

export default function MenuBar() {
    const { currentUser } = useUser();
    const username = currentUser?.email || currentUser?.uid;
    const { specialState } = useParams();
    const discordLink = "https://discord.gg/8Ju3mHesR5";
    const appStoreLink = "https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956";

    return (
        <div className="w-full h-10 flex items-center justify-between px-4 border-b border-gray-900 fixed top-0 z-50 bg-gray-900">
            <div className="flex items-center">
                <MenuDownloadCTA appStoreLink={appStoreLink}/>
            </div>
            <div className="flex items-center">
                <a href={discordLink} target="_blank" rel="noopener noreferrer" className="mr-4">
                    <button className="bg-[#5865F2] text-white font-semibold px-2 py-1 rounded inline-flex items-center hover:">
                        <img src="https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/discord_white.png?alt=media&token=bc96f6d2-ebd5-44ba-87fc-4db30b2258d9" className="mr-2 h-4 w-5" alt="Discord logo" />
                        Discord
                    </button>
                </a>
                <DropdownMenu username={username} specialState={specialState}/>
            </div>
        </div>
    );
}

const MenuDownloadCTA = ({ appStoreLink }) => {
  return (
    <div className="flex items-center group/menu">
      {/* Text - hidden on mobile, visible on md and up */}
      <p className="hidden md:block text-white pr-4 font-bold relative pt-2">
        {/* Gradient text effect */}
        <span className="bg-gradient-to-r from-red-400 via-purple-400 to-orange-400 bg-[length:200%_auto] bg-clip-text text-transparent animate-[gradient_3s_ease-in-out_infinite] hover:bg-[length:300%_auto] transition-[background-size] duration-500">
          Download the app to watch
        </span>
        
        {/* Subtle highlight underline animation */}
        <span className="absolute bottom-0 left-0 w-0 h-0.5 bg-purple-400/50 group-hover/menu:w-full transition-all duration-500" />
      </p>

      {/* Download button - always visible */}
      <a 
        href={appStoreLink}
        target="_blank"
        rel="noopener noreferrer"
        className="flex justify-center items-center relative group"
      >
        {/* Glow effect on hover */}
        <div className="absolute inset-0 bg-purple-500/20 rounded-lg blur-md opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
        
        {/* Shine effect */}
        <div className="absolute inset-0 rounded-lg overflow-hidden">
          <div className="absolute top-0 -left-full w-full h-full bg-gradient-to-r from-transparent via-white/20 to-transparent group-hover:translate-x-full duration-1000 transition-transform" />
        </div>

        <img 
          src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" 
          alt="Download on the App Store" 
          className="h-10 transition-all duration-300 hover:scale-105 relative z-10 pt-2
                     hover:brightness-110 filter group-hover:drop-shadow-[0_0_8px_rgba(168,85,247,0.5)]"
        />

        {/* Pulsing dot indicator */}
        <span className="absolute -top-1 -right-1 flex h-2 w-2 pt-2">
          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-purple-400 opacity-75" />
          <span className="relative inline-flex rounded-full h-2 w-2 bg-purple-500" />
        </span>
      </a>
    </div>
  );
};
