function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return `${text.substring(0, maxLength)}...`;
  }
}

function getEndpoint() {

  return 'https://ai-apps-backend-80af17cb1aaa.herokuapp.com';
  // return 'http://127.0.0.1:2999';
}

const infiniteThumbnail = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/header_thumbnails%2Feggnog_thumbnails.png?alt=media'
export {
	truncateText,
  infiniteThumbnail,
  getEndpoint
}