import React, { useState, useRef } from 'react';
import { logVideoDownload } from '../Firebase';
import { IoMdPlay, IoMdPause, IoMdDownload } from "react-icons/io";
import { getEndpoint } from '../utils';

const VideoItem = ({ video, currentUser }) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false); // State to track play/pause status

  const togglePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused || videoRef.current.ended) {
        videoRef.current.play();
        setIsPlaying(true); // Update state to playing
      } else {
        videoRef.current.pause();
        setIsPlaying(false); // Update state to paused
      }
    }
  };

  const handleDownload = async () => {
    // Check if the user is on Safari
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  
    // Log the event to the backend
    const data = {
      user: currentUser.email,
      event_name: 'compose-download-video',
      video_url: video.video_url
    };
    try {
      await fetch(`${getEndpoint()}/log`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          
        },
        body: JSON.stringify(data),
      });
  
      if (isSafari) {
        try {
          await navigator.clipboard.writeText(video.video_url);
          alert('Video URL copied to clipboard!');
        } catch (error) {
          console.error('Failed to copy video URL:', error);
          alert('Please copy manually and open in a new tab:\n' + video.video_url);
        }
      } else {
        window.open(`https://ai-apps-backend-80af17cb1aaa.herokuapp.com/download-video?videoUrl=${encodeURIComponent(video.video_url)}`, '_blank');
      }
    } catch (error) {
      console.error('Error logging event to backend:', error);
    }
  };  

  return (
    <div className="w-full h-full relative">
      <video src={video.video_url} ref={videoRef} className="w-full h-full object-cover border rounded shadow"></video>
      <div className="absolute bottom-0 right-0 m-2 flex items-center">
        {/* Play/Pause Button */}
        <button
          className="p-2 m-2 bg-gray-500 text-white rounded"
          onClick={togglePlayPause}
        >
          {isPlaying ? <IoMdPause /> : <IoMdPlay />}
        </button>

        {/* Download/Share Button */}
        <button
          className="p-2 bg-blue-500 text-white rounded"
          onClick={handleDownload}
        >
          <IoMdDownload />
        </button>
      </div>
    </div>
  );
};

export default VideoItem;
