import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../Firebase.js';
import { getEndpoint } from '../utils';


const LoginPage = ({ onLoginSuccess }) => {
  const { specialState } = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showLegacyLogin, setShowLegacyLogin] = useState(false);
  const navigate = useNavigate();

  const trackEvent = async (userEmail) => {
    const data = {
      user: userEmail, // Use email as the user identifier
      event_name: 'create-user', // Specify the event name
    };

    fetch(`${getEndpoint()}/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Check if the user is new
      const isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime;
      
      if (isNewUser) {
        console.log('User created a new account');
        trackEvent(user.email);
      }

      // onLoginSuccess(user);
      const playgroundPath = specialState ? `/infinite/${specialState}` : '/infinite';
      navigate(playgroundPath);
    } catch (error) {
      if (error.code === 'auth/credential-already-in-use') {
        setError('This Google account is already linked with another user.');
      } else {
        setError(error.message);
      }
      console.error('Error during Google sign-in:', error);
    }
  };

  const handleEmailLogin = async (e) => {
    e.preventDefault();
    try {
      await setPersistence(auth, browserLocalPersistence);
      const userCredential = await signInWithEmailAndPassword(auth, email.toLowerCase(), password);
      const user = userCredential.user;

      // Check if the user is new
      const isNewUser = user.metadata.creationTime === user.metadata.lastSignInTime;

      if (isNewUser) {
        console.log('User created a new account');
        trackEvent(user.email);
      }

      const playgroundPath = specialState ? `/playground/${specialState}` : '/playground';
      navigate(playgroundPath);
    } catch (error) {
      console.error(error);
      if (error.message === "Error (auth/user-disabled).") {
        setError("Firebase error");
      } else {
        setError(error.message);
      }
    }
  };

  const toggleLegacyLogin = () => {
    setShowLegacyLogin(!showLegacyLogin);
    setError('');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="max-w-md w-full space-y-8">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Sign in to your Eggnog account
          </h2>
          <button
            onClick={handleGoogleSignIn}
            className="mt-8 flex items-center justify-center w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm hover:bg-indigo-800 bg-indigo-700 text-white font-bold"
          >
            Log in with Google
          </button>
          <p className="mt-4">
            <span onClick={toggleLegacyLogin} className="cursor-pointer text-gray-600 hover:text-indigo-500 text-sm">I already have an email and password</span>
          </p>
        </div>
        {showLegacyLogin && (
          <form className="mt-8 space-y-6" onSubmit={handleEmailLogin}>
            <div className="rounded-md shadow-sm">
              <div>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.toLowerCase())}
                />
              </div>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required  
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </div>
            {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Log in
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginPage;