import React from 'react';
import { Download, Sparkles, ExternalLink } from "lucide-react";

const ModernBannerElement = () => (
  <div className="relative h-12 bg-gradient-to-r from-purple-900 via-indigo-900 to-purple-900 overflow-hidden group">
    {/* Animated gradient background */}
    <div className="absolute inset-0 bg-[linear-gradient(45deg,transparent_25%,rgba(168,85,247,0.2)_50%,transparent_75%)] bg-[length:200%_200%] animate-[shimmer_4s_linear_infinite]" />
    
    {/* Glowing orbs */}
    <div className="absolute inset-0 overflow-hidden">
      <div className="absolute top-1/2 left-1/4 w-32 h-32 bg-pink-500/30 rounded-full -translate-y-1/2 blur-2xl animate-pulse" />
      <div className="absolute top-1/2 right-1/4 w-32 h-32 bg-blue-500/30 rounded-full -translate-y-1/2 blur-2xl animate-pulse" />
    </div>

    {/* Main content container */}
    <div className="relative h-full flex items-center justify-between px-6">
      {/* Left side animated text */}
      <div className="flex items-center space-x-3">
        <Sparkles className="w-5 h-5 text-purple-300 animate-[spin_4s_linear_infinite]" />
        <span className="hidden md:inline text-transparent bg-clip-text bg-gradient-to-r from-purple-300 via-pink-300 to-purple-300 font-medium animate-gradient">
          Watch your favorite shows
        </span>
      </div>

      {/* Center section */}
      <div className="absolute left-1/2 -translate-x-1/2 flex items-center space-x-2">
        <div className="flex items-center space-x-2 bg-white/10 rounded-full p-1.5 backdrop-blur-sm border border-white/10">
          <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse" />
          <span className="text-white/90 text-sm pr-1.5">Live</span>
        </div>
      </div>

      {/* Download button */}
      <a
        href="https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956"
        target="_blank"
        rel="noopener noreferrer"
        className="group/btn relative flex items-center space-x-2 bg-gradient-to-r from-purple-600 to-pink-600 
                 p-0.5 rounded-full transition-all duration-300 hover:scale-105 hover:shadow-lg 
                 hover:shadow-purple-500/25 active:scale-95"
      >
        {/* Button inner */}
        <div className="relative flex items-center space-x-2 bg-black/90 px-4 py-1.5 rounded-full">
          <Download className="w-4 h-4 text-white group-hover/btn:animate-bounce" />
          <span className="text-white font-medium">Get the App</span>
          <ExternalLink className="w-4 h-4 text-white transition-transform duration-300 group-hover/btn:translate-x-0.5 group-hover/btn:translate-y--0.5" />
          
          {/* Glow effect on hover */}
          <div className="absolute inset-0 rounded-full opacity-0 group-hover/btn:opacity-100 transition-opacity duration-500">
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600/20 via-pink-600/20 to-purple-600/20 blur-md" />
          </div>
        </div>

        {/* Moving gradient border */}
        <div className="absolute inset-0 rounded-full bg-gradient-to-r from-purple-600 via-pink-600 to-purple-600 animate-[border-rotate_4s_linear_infinite]" style={{ zIndex: -1 }} />
      </a>
    </div>

    {/* Add keyframes for animations */}
    <style jsx global>{`
      @keyframes shimmer {
        0% { background-position: 200% 0; }
        100% { background-position: -200% 0; }
      }

      @keyframes border-rotate {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .animate-gradient {
        animation: gradient 8s linear infinite;
        background-size: 200% 200%;
      }

      @keyframes gradient {
        0% { background-position: 0% 50%; }
        50% { background-position: 100% 50%; }
        100% { background-position: 0% 50%; }
      }
    `}</style>
  </div>
);

export default ModernBannerElement;