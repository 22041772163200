import { useEffect } from "react";

const LivePortraitModal = ({ isOpen, onClose, onVideoClick }) => {
  // Sample static list of videos
  const videos = [
    { name: "anger", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Fanger.mp4?alt=media&token=ee98b1ef-aed3-4d54-80f1-16c4ea8849c9" },
    { name: "anguish", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Fanguish.mp4?alt=media&token=08084d73-7173-439e-9835-22d836314d87" },
    { name: "curiosity", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Fcuriosity.mp4?alt=media&token=fc4f0c56-722f-4281-a887-4369094f453e" },
    { name: "excited", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Fexcited.mp4?alt=media&token=42236dc8-5bdd-4836-87aa-1042b9efde95" },
    { name: "eyeroll", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Feyeroll.mp4?alt=media&token=5b9e1e00-dbf4-49b7-9676-572122fd5cf1" },
    { name: "look_left", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Flook_left.mp4?alt=media&token=79d7965e-395c-4666-85bd-3139757deb34" },
    { name: "look_right", url: "https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/jits_liveportrait_faces%2Flook_right.mp4?alt=media&token=47842e39-c3dc-4c9b-85a6-8dccfa35e8d5" },
  ];

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    document.addEventListener("keydown", handleEsc);
    return () => document.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-100 bg-opacity-90 z-50 flex justify-center items-center p-4">
      <div className="relative max-w-5xl max-h-[90vh] w-full h-full overflow-auto rounded-lg shadow-lg p-4 bg-white">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 bg-gray-200 rounded-full w-8 h-8 flex items-center justify-center hover:bg-gray-300 transition-colors"
          aria-label="Close modal"
        >
          &times;
        </button>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-10">
          {videos.map((video, index) => (
            <div key={index} className="flex flex-col items-center">
              <h3 className="text-gray-800 mb-2">{video.name}</h3>
              <video
                src={video.url}
                className="w-full h-auto rounded-lg shadow-md cursor-pointer"
                controls
                autoPlay
                loop
                onClick={() => onVideoClick(video.url)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LivePortraitModal;
