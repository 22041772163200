import { useState, useEffect } from 'react';
import { db } from '../Firebase.js';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';

const FirebaseRateLimiter = (initialUsername) => {
  const [isLimited, setIsLimited] = useState(false);
  const [username, setUsername] = useState(initialUsername);

  const getRateLimitDoc = () => {
    if (!username) {
      console.warn("Username is undefined in getRateLimitDoc");
      return null;
    }
    return doc(db, "rateLimits", username);
  };

  const addRequest = async () => {
    const rateLimitRef = getRateLimitDoc();
    if (!rateLimitRef) {
      console.warn("rateLimitRef is null in addRequest");
      return;
    }

    const rateLimitDoc = await getDoc(rateLimitRef);
    
    const now = Date.now();
    let requests = rateLimitDoc.exists() ? rateLimitDoc.data().requests : [];
    
    requests.push(now);
    requests = requests.filter(time => time > now - 3600000); // Keep only last hour

    await setDoc(rateLimitRef, { requests, lastUpdated: serverTimestamp() });
  };

  const checkLimit = async () => {
    const rateLimitRef = getRateLimitDoc();
    if (!rateLimitRef) {
      console.warn("rateLimitRef is null in checkLimit");
      return true; // Assume within limit if we can't check
    }

    const rateLimitDoc = await getDoc(rateLimitRef);
    
    if (!rateLimitDoc.exists()) {
      console.log("Rate limit document does not exist");
      setIsLimited(false);
      return true;
    }

    const requests = rateLimitDoc.data().requests;
    const now = Date.now();
    const recentRequests = requests.filter(time => time > now - 60000);
    const hourlyRequests = requests.filter(time => time > now - 3600000);

    const isOverLimit = recentRequests.length >= 3 || hourlyRequests.length >= 30;
    setIsLimited(isOverLimit);

    console.log("recentRequests: ", recentRequests);
    console.log("hourlyRequests: ", hourlyRequests);

    console.log("Is over limit:", isOverLimit);
    return !isOverLimit;
  };

  useEffect(() => {
    setUsername(initialUsername);
  }, [initialUsername]);

  useEffect(() => {
    if (!username) return;

    const interval = setInterval(async () => {
      await checkLimit();
    }, 60000);

    return () => clearInterval(interval);
  }, [username]);

  return { addRequest, checkLimit, isLimited };
};

export default FirebaseRateLimiter;