import { v4 as uuidv4 } from 'uuid'; // for generating a unique ID for each file
import { getStorage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from "firebase/storage";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, doc, setDoc, getDocs, getDoc, serverTimestamp, where, query, updateDoc, arrayUnion, onSnapshot } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import axios from 'axios';


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_BASE_API_KEY,
  authDomain: "fastab-f08e9.firebaseapp.com",
  // authDomain: "eggnog.ai",
  projectId: "fastab-f08e9",
  storageBucket: "fastab-f08e9.appspot.com",
  messagingSenderId: "439667307825",
  appId: "1:439667307825:web:1e0a4cda7ac3bda5376c10",
  measurementId: "G-31TL7C8J18"
};

const app = initializeApp(firebaseConfig);

const storage = getStorage();
const db = getFirestore(app);
const auth = getAuth();


const getFileExtension = (fileName) => {
  return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
};

const extractEmails = (text) => {
    const emailRegex = /[\w.-]+@[\w.-]+\.\w+/g;
    return text.match(emailRegex) || [];
};

const uploadVideoToFirebase = async (videoFile, currentUserEmail) => {
  const videoId = uuidv4();
  const fileExtension = videoFile.name ? videoFile.name.split('.').pop().toLowerCase() : 'mp4';

  const metadata = {
    contentType: videoFile.type,
    cacheControl: 'public, max-age=31536000',
  };

  const videoRef = ref(storage, `user_submitted_videos/${videoId}.${fileExtension}`);
  const uploadTask = uploadBytesResumable(videoRef, videoFile, metadata); // Pass the file directly here

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed', 
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Upload failed:', error);
        reject(error);
      }, 
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File available at', downloadURL);
        
        // Update user's document with the new video
        await updateUserUploadedVideos(currentUserEmail, downloadURL);

        resolve(downloadURL);
      }
    );
  });
};

const updateUserUploadedVideos = async (userEmail, videoUrl) => {
  const userDocRef = doc(db, 'character-users', userEmail);

  // Use the ISO string representation of the current time
  const nowISO = new Date().toISOString();

  const videoObject = {
    url: videoUrl,
    timestamp: nowISO // Using ISO string format
  };

  try {
    await updateDoc(userDocRef, {
      uploaded_videos: arrayUnion(videoObject) // Directly use the object
    });
    console.log("User's uploaded_videos field updated with new video URL and ISO string timestamp");
  } catch (error) {
    console.error("Error updating user's uploaded_videos field:", error);
  }
};

const updateVideoInFirestore = async (docId, videoUrl) => {
  const firestore = getFirestore();
  const docRef = doc(firestore, "petPhotos", docId); // Replace 'collectionName' with your actual collection name

  await updateDoc(docRef, {
    pet_video: videoUrl,
    timestamp: serverTimestamp()
  });
};

const uploadPhotoToFirebase = async (photoFile) => {
  const photoId = uuidv4();
  const fileExtension = getFileExtension(photoFile.name);

  const photoRef = ref(storage, `user_photos/${photoId}.${fileExtension}`);
  const uploadTask = uploadBytesResumable(photoRef, photoFile);

  return new Promise((resolve, reject) => {
    uploadTask.on('state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
      },
      (error) => {
        console.error('Upload failed:', error);
        reject(error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        console.log('File available at', downloadURL);
        resolve(downloadURL);
      }
    );
  });
};

async function getDocumentWithId(collectionPath, docId) {
  try {
    const docRef = doc(db, collectionPath, docId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    throw error;
  }
}

const updateSharedStatus = async (docId) => {
    try {
      await updateDoc(doc(db, 'petPhotos', docId), { shared_screenshots_verified: true });
      // await updateDoc(doc(db, 'petPhotos', docId), { user_first_name: firstName });
    } catch (error) {
        console.error('Error updating document:', error);
        throw error; // Or handle it as needed
    }
};

const formatCharacterName = (appName) => {
  // Replace spaces with dashes and convert to lower case for consistent doc IDs
  return appName.replace(/\s+/g, '-').toLowerCase(); 
}

const createUserDocument = async (username) => {

  const docRef = doc(db, 'character-users', username);
  const docContent = {
    finetune_predictions: [],
    playground_predictions: [],
    seen_intro: false,
  };

  try {
    // Create a new document with the specified content
    await setDoc(docRef, docContent);
    console.log(`Document created for user ${username}`);
  } catch (error) {
    console.error("Error creating document:", error);
  }
}

// Function to retrieve documents based on email
async function getCharactersByEmail(email) {
  const db = getFirestore(); // Get a reference to the Firestore service
  const usersCollectionRef = collection(db, 'character-finetunes'); // Reference to the collection
  const q = query(usersCollectionRef, where('owner_email', '==', email)); // Create a query against the collection

  try {
    const querySnapshot = await getDocs(q); // Execute the query
    const documents = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      documents.push({ id: doc.id, ...doc.data() }); // Add the document id and data to the documents array
    });
    return documents; // Return the array of documents
  } catch (error) {
    console.error('Error getting documents: ', error);
    return []; // Return an empty array in case of error
  }
}

const logVideoDownload = async (videoUrl, user) => {
  const downloadLogRef = doc(collection(db, "video-downloads"));
  try {
    await setDoc(downloadLogRef, {
      videoUrl: videoUrl,
      user: user,
      timestamp: serverTimestamp()
    });
    console.log("Video download logged successfully");
  } catch (error) {
    console.error("Error logging video download:", error);
  }
};

const fetchAllDocuments = async (collectionName) => {
  const db = getFirestore();
  const collectionRef = collection(db, collectionName);
  const snapshot = await getDocs(collectionRef);
  const documents = [];
  snapshot.forEach(doc => {
    // documents[doc.id] = doc.data();
    documents.push(doc.data());
  });

  return documents;
}

const uploadMedaToFirebase = async (file, docId, subdir = 'reaction_comedy/content/') => {
  const storage = getStorage();
  
  // Check if the file type is allowed
  const allowedTypes = ['video/mp4', 'image/jpeg', 'image/jpg', 'image/png'];
  if (!allowedTypes.includes(file.type)) {
    throw new Error('Invalid file type. Only .mp4, .jpeg, .jpg, and .png files are allowed.');
  }

  // Generate a unique filename using UUID
  const extension = file.name.split('.').pop().toLowerCase();
  const filename = `${docId}_${uuidv4()}.${extension}`;

  // Ensure the subdir ends with a forward slash
  const normalizedSubdir = subdir.endsWith('/') ? subdir : `${subdir}/`;

  // Create a reference to the file location in Firebase Storage
  const storageRef = ref(storage, `${normalizedSubdir}${filename}`);

  try {
    // Upload the file to Firebase Storage
    const snapshot = await uploadBytes(storageRef, file);
    console.log('Uploaded a file!', snapshot);

    // Get the download URL
    const downloadURL = await getDownloadURL(snapshot.ref);
    console.log('File available at', downloadURL);

    return downloadURL;
  } catch (error) {
    console.error('Error uploading file: ', error);
    throw error;
  }
};



export {
  app,
  storage,
  db,
  auth,
  extractEmails,
  getDocumentWithId,
  updateSharedStatus,
  uploadVideoToFirebase,
  updateVideoInFirestore,
  uploadPhotoToFirebase,
  createUserDocument,
  getCharactersByEmail,
  formatCharacterName,
  logVideoDownload,
  fetchAllDocuments,
  uploadMedaToFirebase
}
