import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { auth, createUserDocument } from '../Firebase.js'
import PromptInput from '../Components/PromptInput.jsx'
import ResultsGrid from '../Components/ResultsGrid.jsx' 
import { useUser } from '../contexts/UserContext';

import { db } from '../Firebase.js' 
import { doc, onSnapshot, setDoc, collection } from 'firebase/firestore';

import ImageModal from '../Components/ImageModal.jsx'
import IntroModal from '../Components/IntroModal.jsx'
import { v4 as uuidv4 } from 'uuid';
import MenuBar from '../MenuBar'
import ModeSelector from './ModeSelector.jsx'
import PresetSelector from './PresetSelector.jsx'
import {GENERAL, FACE, DETAIL} from './presets.js'
import { getEndpoint } from '../utils';



const CREATE_STATE = {
  General: 0,
  Face: 1,
  Detail: 2,
  Confirm: 3,

};

const MODE = {
  Preset: 'Preset',
  Customize: 'Customize',
};



const makeFacePrompt = (general, face) => `A photo portrait of ${general}, ${face}, RAW candid cinema, 16mm, color graded portra 400 film, remarkable color, ultra realistic, textured skin, remarkable detailed pupils, realistic dull skin noise, visible skin detail, skin fuzz, dry skin, shot with cinematic camera`;
const makeDetailPrompt = (general, face, details) => `(a photo shoot of ${general}, in different poses:1.4), ((consistent clothing)), ${details}, cinematic lighting, Hyperrealism, depth of field, photography, ultra highres, photorealistic, 8k, hyperrealism, studio lighting, photography`
const negative_prompt = 'easynegative, canvasframe, canvas frame, eyes shut, wink, blurry, hands, closed eyes, (easynegative), ((((ugly)))), (((duplicate))), ((morbid)), ((mutilated)), out of frame, extra fingers, mutated hands, ((poorly drawn hands)), ((poorly drawn face)), ((bad art)), blurry, (((mutation))), (((deformed))), blurry, ((bad anatomy)), (((bad proportions))), ((extra limbs)), cloned face, (((disfigured))), gross proportions, (malformed limbs), ((missing arms)), ((missing legs)), ((floating limbs)), ((disconnected limbs)), ((malformed hands)), ((missing fingers)), worst quality, ((disappearing arms)), ((disappearing legs)), (((extra arms))), (((extra legs))), (fused fingers), (too many fingers), (((long neck))), canvas frame, ((worst quality)), ((low quality)), lowres, sig, signature, watermark, username, bad, immature, cartoon, anime, 3d, painting, b&w';


const CreateCharacter = () => {
  const navigate = useNavigate();

  const { specialState } = useParams();

  const [userData, setUserData] = useState(null)
  const [step, setStep] = useState(CREATE_STATE.General)
  const [mode, setMode] = useState(MODE.Preset)

  
  const [generalPrompt, setGeneralPrompt] = useState('')
  const [generalPresets, setGeneralPresets] = useState([])

  const [facePrompt, setFacePrompt] = useState('')
  const [facePresets, setFacePresets] = useState([])

  const [detailPrompt, setDetailPrompt] = useState('')
  const [detailPresets, setDetailPresets] = useState([])

  const [faceConfig, setFaceConfig] = useState(null)
  const [detailConfig, setDetailConfig] = useState(null)
  const [characterName, setCharacterName] = useState('')

  const [introModalOpen, setIntroModalOpen] = useState(false)
  const [imageModalOpen, setImageModalOpen] = useState(false)
  const [imagesForModal, setImagesForModal] = useState([])

  const {currentUser, loading, idToken} = useUser();
  const username = currentUser?.email || currentUser?.uid;

  const trackEvent = (eventName, eventData) => {
    const data = {
        user: currentUser.email,
        event_name: eventName,
        ...eventData
    };

    fetch(`${getEndpoint()}/log`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,

        },
        body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(data => {
        console.log('Event tracked successfully:', data);
    })
    .catch((error) => {
        console.error('Error tracking event:', error);
    });
  };

  useEffect(() => {
    if (username == null) return
    const docRef = doc(db, "character-users", username);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data()
        setUserData(data);
      } else {
        console.log("No such document!");
      }
    });

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  useEffect(() => {
    if (!username) return;
  
    const predictionsRef = collection(db, "character-users", username, "playground_predictions");
    const unsubscribe = onSnapshot(predictionsRef, (snapshot) => {
      const predictions = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUserData((prevData) => ({ ...prevData, playground_predictions: predictions }));
    });
  
    return () => unsubscribe();
  }, [username]);  

  const facePredictions = userData?.playground_predictions?.filter(prediction => prediction.photo_type === "face");
  const bodyPredictions = userData?.playground_predictions?.filter(prediction => prediction.photo_type === "body");

  // TODO can probably improve this state parsing
  if (loading) return <div>Loading...</div>;  
  if (currentUser == null) {
    const loginPath = specialState ? `/login/${specialState}` : '/login';
    navigate(loginPath);
  }
  if (userData == null) return <div>Loading...</div>;
  

  const generalContinuePressed = () => {
    setStep(CREATE_STATE.Face);
    setFacePresets([])
    trackEvent('create-continue-pressed', {});
    setMode(MODE.Preset)
  }

  const faceContinuePressed = () => {
    setStep(CREATE_STATE.Detail);
    setDetailPresets([])
    trackEvent('create-first-use-pressed', {});
    setMode(MODE.Preset)

  }

  const faceBackPressed = () => {
    setStep(CREATE_STATE.General); 
  }

  const detailContinuePressed = () => {
    setStep(CREATE_STATE.Confirm);
    trackEvent('create-second-use-pressed', {});
  }

  const detailBackPressed = () => {
    setStep(CREATE_STATE.Face);
  }

  const confirmBackPressed = () => {
    setStep(CREATE_STATE.Detail);
  }

  const seeImages = (images) => {
    setImagesForModal(images)
    setImageModalOpen(true)

  }
  const onImageModalClose = () => {
    setImagesForModal([])
    setImageModalOpen(false)
  }

  const clickedResult = (...args) => {
    const selectedImage = args[args.length - 2];
  
    const newConfig = {
      imageUrl: selectedImage,
    };
  
    if (step === CREATE_STATE.Face) {
      setFaceConfig(newConfig);
      faceContinuePressed();
    } else if (step === CREATE_STATE.Detail) {
      setDetailConfig(newConfig);
      detailContinuePressed();
    }
  };
  
  const onSubmitCharacter = async () => {
    const endpoint = `${getEndpoint()}/create_character`;
    const data = {
      user: username,
      reference_face_url: faceConfig.imageUrl,
      character_sheet_url: detailConfig.imageUrl,
      character_type: generalPrompt,
      character_details: `${facePrompt}, ${detailPrompt}`,
      character_name: characterName,
      raw_prompt: `${generalPrompt}//${facePrompt}//${detailPrompt}`,
    }
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
      body: JSON.stringify(data)
    });
    if (response.ok) {
      const responseBody = await response.json();
      trackEvent('successful-submit-character', {});
      const profilePath = specialState ? `/profile/${specialState}` : '/profile';
      navigate(profilePath);
    } else {
      // Handle the error
      console.error('/create_character failed');
    }

  }

  const createProcessingDocument = async (prompt, rawPrompt, negativePrompt, photoType) => {
    const predictionId = `${username}-${new Date().toISOString()}`;
    const processingDocument = {
      prediction_id: predictionId,
      prompt: prompt,
      raw_prompt: rawPrompt,
      negative_prompt: negativePrompt,
      status: 'PROCESSING',
      photo_type: photoType,
      timestamp: new Date().toISOString(),
    };
  
    // Firestore document reference
    const docRef = doc(db, "character-users", username, "playground_predictions", predictionId);
    await setDoc(docRef, processingDocument);
  
    return predictionId;  // Return the prediction ID to use in the backend call
  };

  const onEnter = async (step) => {
    
    let prompt, rawPrompt, photoType;

    if (step == CREATE_STATE.Face){
      prompt = makeFacePrompt(generalPrompt, facePrompt);
      rawPrompt = `${generalPrompt}///${facePrompt}`
      photoType = 'face';
    }
    else if (step == CREATE_STATE.Detail){
      prompt = makeDetailPrompt(generalPrompt, facePrompt, detailPrompt);
      rawPrompt = `${generalPrompt}///${facePrompt}///${detailPrompt}`
      photoType = 'body';
    }

    const predictionId = await createProcessingDocument(prompt, rawPrompt, negative_prompt, photoType);

    const data = {
      "user": username,
      "prompt": prompt,
      "raw_prompt": rawPrompt,
      "negative_prompt": negative_prompt, 
      "photo_type": photoType,
      "prediction_id": predictionId,
    };

    const endpoint = `${getEndpoint()}/create_photo`;
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`,
      },
      body: JSON.stringify(data)
    });


    if (response.ok) {
      const responseBody = await response.json();
    } else {
      // Handle the error
      console.error('/create_photo failed');
    }


  }

  const onGeneralSelect = (prompts) => setGeneralPrompt(prompts.join(', '));
  const onFaceSelect = (prompts) => setFacePrompt(prompts.join(', '));
  const onDetailSelect = (prompts) => setDetailPrompt(prompts.join(', '));


  let facePresetOptions, detailPresetOptions;
  if (generalPrompt in GENERAL){
    const generalPresetKey = GENERAL[generalPrompt]
    facePresetOptions = FACE[generalPresetKey]
    detailPresetOptions = DETAIL[generalPresetKey]
  } else {
    facePresetOptions = ['piercing eyes', 'shaggy blond hair', 'long black hair','thick black glasses', 'bushy brown beard', 'earrings']
    detailPresetOptions = ['military uniform', 'red baseball cap', 'red and blue jumpsuit', 'tuxedo', 'sparkling white dress']
  }

  const characterSummary = []
  if (step > CREATE_STATE.General) characterSummary.push(['Archetype', generalPrompt]);
  if (step > CREATE_STATE.Face) characterSummary.push(['Face', facePrompt]);
  if (step > CREATE_STATE.Detail) characterSummary.push(['Body', detailPrompt]);

  const generalPromptMin = 10;
  const generalPromptMax = 60;
  const submitGeneralIsDisabled = generalPrompt.length < generalPromptMin || generalPrompt.length > generalPromptMax;

  return (
    <div className="w-screen">
      <MenuBar />
      <div className="mt-10 flex flex-col sm:flex-row bg-gray-100">
        <div className="px-2 flex-grow space-y-8 py-8 flex justify-center w-full sm:w-3/4 lg:w-2/3 xl:w-1/2">
          {
            (step === CREATE_STATE.General) && (
              <div className='max-w-4xl w-full space-y-4'> 
                <div className='flex flex-row justify-between'>
                  <p className='text-gray-800 text-2xl font-bold'>Step 1: What kind of character are you making?</p>
                </div>
                <ModeSelector mode={mode} setMode={setMode} firstModeLabel={MODE.Preset} secondModeLabel={MODE.Customize}/>
                {
                  (mode === MODE.Preset) ? (
                    <PresetSelector
                      selected={generalPresets}
                      setSelected={setGeneralPresets}
                      presets={Object.keys(GENERAL)}
                      onSelect={onGeneralSelect}
                      multiSelect={false}
                      prefix={null}
                      header={null}/>
                  ) : (
                    <PromptInput 
                      prompt={generalPrompt} 
                      setPrompt={setGeneralPrompt} 
                      promptPrefix={''} 
                      enterPressed={() => onEnter(CREATE_STATE.General)} 
                      enterToSubmit={false}
                      examples={["An ancient greek man","An african woman","A viking man from the middle ages"]}
                      lengthLimits={true}
                      minLength={generalPromptMin}
                      maxLength={generalPromptMax}
                      characterSelected={true}
                    />
                  )
                }
                <div className='flex flex-row-reverse'>
                  <button
                    onClick={generalContinuePressed}
                    className='bg-purple-500 text-white my-2 p-2 hover:bg-gray-800 disabled:bg-gray-400 font-bold rounded' 
                    disabled={submitGeneralIsDisabled}>
                    Continue
                  </button>
                </div>
              </div>
            )
          }

          {
            (step == CREATE_STATE.Face) && (
              <div className='max-w-4xl w-full space-y-4'>
                <button onClick={faceBackPressed} className='text-blue-500 py-1'>Back</button>
                <div className='flex flex-row justify-between'>
                  <p className='text-gray-800 text-2xl font-bold'>Step 2: Give your character a face</p>
                </div>
                <div className='bg-gray-200 rounded p-3'>
                  <ModeSelector mode={mode} setMode={setMode} firstModeLabel={MODE.Preset} secondModeLabel={MODE.Customize}/>
                  {
                    (mode == MODE.Preset) ? (
                      <div>
                        <PresetSelector
                          selected={facePresets}
                          setSelected={setFacePresets}
                          presets={facePresetOptions}
                          onSelect={onFaceSelect}
                          multiSelect={true}
                          prefix={generalPrompt}
                          header={'Add details (optional)'}/>
                        <div className='flex flex-row-reverse'>
                          <button
                            onClick={() => onEnter(CREATE_STATE.Face)} 
                            className='bg-purple-500 text-white mt-2 p-2 hover:bg-green-500 disabled:bg-gray-400 rounded'
                            disabled={facePrompt == ''}>
                            Generate</button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p className='text-gray-500 text-xs mt-2'>Character</p>
                          <p className='bg-gray-300 rounded p-2 my-1'>{generalPrompt}</p>
                        </div>
                        <p className='text-gray-500 text-xs mt-2'>Details</p>
                        <PromptInput 
                          prompt={facePrompt} 
                          setPrompt={setFacePrompt} 
                          promptPrefix={generalPrompt} 
                          enterPressed= {() => onEnter(CREATE_STATE.Face)} 
                          enterToSubmit={true}
                          lengthLimits={false}
                          examples={['thick glasses, beard, wrinkly face', 'jewelry, piercing eyes', 'blue eyes, thick mustache, long brown hair']}
                          characterSelected={true}
                        />
                      </div>
                    )
                  }
                </div>
                <p className='text-xl font-bold text-gray-800 py-2'>Faces</p>
                <ResultsGrid 
                  results={facePredictions}
                  publish={null}
                  showImages={seeImages} 
                  photoType={'face'} 
                  showActionButton={true}
                  actionButtonText={'Select'}
                  onAction={clickedResult} 
                  showAddMore={true}
                  addMoreText={"Start typing to create a new face for your character!"}
                />
              </div>
            )
          }
          {
            (step == CREATE_STATE.Detail) && (
              <div className='max-w-4xl w-full space-y-4'>
                <button onClick={detailBackPressed} className='text-blue-500 py-1'>Back</button>
                <div className='flex flex-row justify-between'>
                  <p className='text-gray-800 text-2xl font-bold'>Step 3: What are they wearing?</p>
                </div>
                <div className='bg-gray-200 rounded p-3'>
                  <ModeSelector mode={mode} setMode={setMode} firstModeLabel={MODE.Preset} secondModeLabel={MODE.Customize}/>
                  {
                    (mode == MODE.Preset) ? (
                      <div>
                        <PresetSelector
                          selected={detailPresets}
                          setSelected={setDetailPresets}
                          presets={detailPresetOptions}
                          onSelect={onDetailSelect}
                          multiSelect={true}
                          prefix={generalPrompt}
                          header={'Add details (optional)'}/>
                        <div className='flex flex-row-reverse'>
                          <button
                            onClick={() => onEnter(CREATE_STATE.Detail)} 
                            className='bg-purple-500 text-white mt-2 p-2 hover:bg-green-500 disabled:bg-gray-400 rounded'
                            disabled={detailPrompt == ''}>
                            Generate</button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p className='text-gray-500 text-xs mt-2'>Character</p>
                          <p className='bg-gray-200 rounded p-2 my-1'>{generalPrompt}</p>
                        </div>
                        <PromptInput 
                          prompt={detailPrompt} 
                          setPrompt={setDetailPrompt} 
                          promptPrefix={''} 
                          enterPressed={() => onEnter(CREATE_STATE.Detail)} 
                          enterToSubmit={true}
                          lengthLimits={false}
                          examples={['gray toga, gold helmet', 'tuxedo, black sunglasses', 'blue jumpsuit, red cape']}
                          characterSelected={true}
                        />
                      </div>
                      
                    )
                  }
                </div>
                <p className='text-xl font-bold text-gray-800 py-2'>Full bodies</p>
                <ResultsGrid 
                  results={bodyPredictions}
                  publish={null}
                  showImages={seeImages} 
                  photoType={'body'} 
                  showId={true} 
                  showActionButton={true}
                  actionButtonText={'Select'}
                  onAction={clickedResult} 
                  showAddMore={true}
                  addMoreText={"Start typing to create a body for your character!"}

                />
              </div>
            )
          }
          {
            (step == CREATE_STATE.Confirm) && (
              <div className='max-w-4xl w-full space-y-4'>
                <button onClick={confirmBackPressed} className='text-blue-500 py-1'>Back</button>
                <div className='flex flex-row justify-between'>
                  <p className='text-gray-800 text-2xl font-bold'>Step 4: Name your character</p>
                </div>
                <textarea
                  className="form-textarea mt-1 block w-full p-2 rounded font-mono border border-gray-300 shadow-sm min-h-8"
                  placeholder={`King Arthur`}
                  value={characterName}
                  onChange={(e) => setCharacterName(e.target.value)}
                ></textarea>
                {/* Note about character availability */}
                <p className="text-sm text-gray-600 mt-4">
                  You and others in the community will be able to use your character in scenes.
                </p>
                <div className='flex flex-row-reverse'>
                  <button
                    onClick={onSubmitCharacter}
                    disabled={characterName == ''}
                    className='bg-black text-white my-2 p-2 hover:bg-gray-800 disabled:bg-gray-400 rounded'>
                    Create Character</button>
                </div>

              </div>
            )
          }
        </div>
        <div className="w-full sm:w-64 sm:border-l sm:border-gray-300 sm:min-h-screen">
          <div className="p-4">
            <p className="text-gray-800 text-xl font-bold">Your Character</p>
            <div className="grid grid-cols-2 md:grid-cols-1 gap-4">
            {
              (faceConfig != null) && (
                <div className='bg-white  my-2 p-2 rounded overflow-hidden shadow-sm hover:shadow-xl transition-shadow duration-300 ease-in-out relative'>
                  <p className='text-left text-xs font-semibold text-gray-400 uppercase tracking-wider'>FACE</p>
                  <img src={faceConfig.imageUrl} className='rounded my-2'/>

                </div>
              )
            }
            {
              (detailConfig != null) && (
                <div className='bg-white  my-2 p-2 rounded overflow-hidden shadow-sm hover:shadow-xl transition-shadow duration-300 ease-in-out relative'>
                  <p className='text-left text-xs font-semibold text-gray-400 uppercase tracking-wider'>DETAIL</p>
                  <img src={detailConfig.imageUrl} className='rounded my-2'/>

                </div>
              )

            }
            </div>
          </div>
        </div>
        <ImageModal isOpen={imageModalOpen} onClose={onImageModalClose} imageUrls={imagesForModal}/>
      </div>
    </div>
  );
};

export default CreateCharacter;
