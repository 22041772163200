import React, { useState, useEffect } from 'react';
import { Download } from "lucide-react";

const AppDownloadCTA = () => {
  const [sparkles, setSparkles] = useState([]);

  useEffect(() => {
    const generateSparkles = () => {
      const newSparkles = Array.from({ length: 20 }, (_, i) => ({
        id: i,
        left: `${Math.random() * 100}%`,
        top: `${Math.random() * 100}%`,
        size: Math.random() * 4 + 1,
        duration: Math.random() * 2 + 1
      }));
      setSparkles(newSparkles);
    };

    generateSparkles();
    const interval = setInterval(generateSparkles, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <a
      href="https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956"
      target="_blank"
      rel="noopener noreferrer"
      className="block w-full max-w-md mx-auto p-8 group"
    >
      <div className="relative">
        {/* Hover effect background rings */}
        <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
          <div className="absolute inset-0 bg-purple-500/20 rounded-xl blur-xl animate-ping" />
          <div className="absolute inset-0 bg-pink-500/20 rounded-xl blur-lg animate-pulse" />
        </div>

        {/* Background gradient effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500/20 to-pink-500/20 rounded-xl blur-xl 
                      group-hover:scale-110 group-hover:rotate-3 transition-all duration-500" />
        
        {/* Main container */}
        <div className="relative bg-white/90 dark:bg-black/90 rounded-xl p-6 backdrop-blur-sm border border-gray-200 
                      dark:border-gray-800 shadow-2xl overflow-hidden
                      group-hover:shadow-purple-500/25 group-hover:scale-105 group-hover:-rotate-1
                      transition-all duration-500 ease-out">
          {/* Custom sparkles */}
          <div className="absolute inset-0">
            {sparkles.map((sparkle) => (
              <div
                key={sparkle.id}
                className="absolute animate-ping"
                style={{
                  left: sparkle.left,
                  top: sparkle.top,
                  width: `${sparkle.size}px`,
                  height: `${sparkle.size}px`,
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  opacity: 0.6,
                  animationDuration: `${sparkle.duration}s`
                }}
              />
            ))}
          </div>

          {/* Content container */}
          <div className="relative z-10 flex flex-col items-center text-center">
            <div className="transform group-hover:scale-110 group-hover:rotate-12 transition-transform duration-500">
              <Download className="w-12 h-12 mb-4 text-purple-600 dark:text-purple-400 
                                group-hover:animate-bounce" />
            </div>

            <h2 className="text-2xl font-bold mb-3 bg-gradient-to-r from-purple-600 to-pink-600 bg-clip-text text-transparent
                         group-hover:tracking-wider transition-all duration-500">
              Get Eggnog TV
            </h2>
            
            <p className="text-gray-600 dark:text-gray-300 mb-6 
                       group-hover:tracking-wide transition-all duration-500">
              Watch fanfics from your favorite worlds
            </p>

            <div className="relative transition-transform duration-500 group-hover:scale-110">
              {/* Glossy effect */}
              <div className="absolute inset-0 rounded-xl bg-gradient-to-tr from-white/40 to-transparent 
                           opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
              
              <img 
                src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
                alt="Download on the App Store"
                className="h-12 relative z-10"
              />
            </div>
          </div>
        </div>
      </div>
    </a>
  );
};

export default AppDownloadCTA;