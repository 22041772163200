import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { ArrowUpRight } from 'lucide-react';
import { getEndpoint } from '../utils';

const ScrollingBanner = ({ text, direction }) => (
  <div className={`overflow-hidden whitespace-nowrap bg-gradient-to-r from-purple-600 to-pink-500 py-2 ${direction === 'left' ? 'animate-scroll-left' : 'animate-scroll-right'}`}>
    <span className="inline-block">{text.repeat(20)}</span>
  </div>
);
export default function LandingPage() {

  const { currentUser, loading } = useUser();
  const [userData, setUserData] = useState(null);
  const username = currentUser?.email || currentUser?.uid;
  
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // This function checks the width of the screen and updates the isMobile state
    function handleResize() {
      setIsMobile(window.innerWidth < 768); // 768px is typically the width for mobile devices
    }

    // Call the function on component mount
    handleResize();

    // Add event listener to listen for screen resize events
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


// @chatGPT -- use these images 
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_stranger_things.png?alt=media&token=83562d36-c32f-4739-9e62-7d7519ab7554
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_breaking.png?alt=media&token=d21fb1f9-4c52-4530-bb0d-fde02cab61a1
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_epstein.png?alt=media&token=7ab1e3df-4501-4236-a1b6-671108467aa1
// https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_bridgerton.png?alt=media&token=cfc38d03-8374-409a-ab36-754b68cde7d4
  const trackEvent = async (eventName, eventData) => {
    const data = {
      user: username,
      event_name: eventName,
      manufacturer: 'Web',      
      ...eventData,
    };

    fetch(`${getEndpoint()}/log`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log('Success:', data);
    })
    .catch((error) => {
      console.error('Error:', error);
    });
  };

  const handleShowClick = (showId) => {
    trackEvent('start-show', { firstEpisode: showId });
    navigate(`/infinite-show/${showId}`);
  };
  const img1 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/infinite_previous_scripts%2Fposter_stranger_things.png?alt=media&token=83562d36-c32f-4739-9e62-7d7519ab7554';
  const img2 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmp%2Fposter_306605.jpg?alt=media';
  const img3 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmp%2Fposter_263089.jpg?alt=media';
  const img4 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmp%2Fposter_7850e186-79e3-41f2-9146-89c18870d7bb.jpg?alt=media';
  const img5 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmp%2Fposter_c6ac4a6f-c272-4dc1-9bbb-1a9ca9ce7cd4.jpg?alt=media';
  const img6 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmp%2Fposter_731619.jpg?alt=media';
  const img7 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/show_posters%2Fbyler_1.png?alt=media';
  const img8 = 'https://firebasestorage.googleapis.com/v0/b/fastab-f08e9.appspot.com/o/tmp%2Fposter_8b6bb183-e1da-4aa7-98b6-5eb231e91e9b.jpg?alt=media';

return (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-black to-gray-800 text-gray-100">
      {/*<ScrollingBanner text="🎉 Welcome to Eggnog TV! 🍿 " direction="left" />*/}
      
      <div className="bg-yellow-400 text-black p-4">
        <div className="flex items-center justify-between">
          <p className="text-lg font-bold flex-grow pr-4">
            On TikTok, hit the 3-dots at the top right. Then tap "Open in browser" to download EGGNOG TV for free.
          </p>
          <ArrowUpRight className="w-12 h-12 text-black animate-bounce flex-shrink-0" />
        </div>
      </div>

      <header className="text-center mb-2 px-4 py-8 relative">
        <h1 className="text-5xl md:text-8xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600 to-red-400 leading-none mb-6 py-2">eggnog tv</h1>
        <p className="text-xl md:text-2xl text-gray-300 max-w-2xl mx-auto leading-relaxed">watch fanfics from your favorite worlds</p>
        <div className="mt-4 inline-block">
          <a 
            href="https://apps.apple.com/us/app/eggnog-infinite-tv/id6590605956"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block"
          >
            <img 
              src="/Download_on_the_App_Store_Badge_US-UK_RGB_wht_092917.svg" 
              alt="Download on the App Store" 
              className="h-12 transition-all duration-300 ease-in-out hover:scale-105 cursor-pointer"
            />
          </a>
        </div>
      </header>
      
      <main className="flex-grow">
        <div className="container mx-auto px-4">
          <div className="text-white py-4 px-2 rounded-lg shadow-lg mb-6 mx-auto w-full md:w-3/4">
          <p className="text-xl md:text-2xl text-gray-500 max-w-2xl">these shows, and more...</p>
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
              {[img1, img2, img3, img4, img5, img6, img7, img8].map((img, index) => (
                <div key={index} className="block rounded-lg shadow-lg overflow-hidden cursor-pointer relative" >
                  <img src={img} alt={`Show ${index + 1}`} className="rounded-lg shadow-lg w-full h-auto" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </main>

      <footer className="text-center text-gray-400 py-4">
        <p>© 2024 Eggnog. All rights reserved.</p>
        <a href="/privacy-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">Privacy Policy</a> | <a href="/eula-policy" className="hover:text-purple-300 transition-colors duration-200 ease-in-out">EULA</a>
      </footer>

      {/*<ScrollingBanner text="🔥 Hot Shows! 📺 New Releases! " direction="right" />*/}
    </div>
  );

}
