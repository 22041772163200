import React, { useState, useEffect } from 'react';
import { doc, setDoc, collection, query, orderBy, onSnapshot, addDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../Firebase.js' 

export default function EpisodesManager({characterName, finalAssets, mediaUrl }) {
  const [episodes, setEpisodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch episodes on component mount
  useEffect(() => {
    const q = query(collection(db, 'episodes-funny'), orderBy('date', 'desc'));
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const episodesData = [];
      querySnapshot.forEach((doc) => {
        episodesData.push({ id: doc.id, ...doc.data() });
      });
      setEpisodes(episodesData);
    });

    return () => unsubscribe();
  }, []);

  const createNewEpisode = async () => {
    setIsLoading(true);
    setError(null);
    
    try {
      // Determine content type from mediaUrl
      const contentType = mediaUrl.toLowerCase().includes('.mp4') ? 'video' : 'image';
      
      // Create new episode document
      const newEpisode = {
        date: new Date().toISOString().split('T')[0],
        content_url: mediaUrl,
        content_type: contentType,
        reactions: finalAssets ? [{
          audio_url: finalAssets.audio_url,
          gif_url: finalAssets.gif_url,
          video_url: finalAssets.video_url,
          name: characterName
        }] : []
      };
      console.log("Adding doc: ", newEpisode)

      await addDoc(collection(db, 'episodes-funny'), newEpisode);
      
    } catch (err) {
      setError('Failed to create new episode: ' + err.message);
      console.error('Error creating new episode:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const appendToEpisode = async (episodeId) => {
    if (!finalAssets) {
      setError('No final assets available to append');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const episodeRef = doc(db, 'episodes-funny', episodeId);
      
      await updateDoc(episodeRef, {
        reactions: arrayUnion({
          audio_url: finalAssets.audio_url,
          gif_url: finalAssets.gif_url,
          video_url: finalAssets.video_url,
          name: characterName
        })
      });
      // console.log("Adding doc: ", newEpisode)
    } catch (err) {
      setError('Failed to append to episode: ' + err.message);
      console.error('Error appending to episode:', err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mt-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Episodes</h2>
        <button
          onClick={createNewEpisode}
          disabled={isLoading || !finalAssets}
          className={`px-4 py-2 rounded ${
            isLoading || !finalAssets
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-green-500 hover:bg-green-600 text-white'
          }`}
        >
          Create New Episode
        </button>
      </div>

      {error && (
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
          {error}
        </div>
      )}

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded-lg">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reactions</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {episodes.map((episode) => (
              <tr key={episode.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {new Date(episode.date).toLocaleDateString()}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {episode.content_type}
                </td>
                <td className="px-6 py-4">
                  {episode.content_type === 'video' ? (
                    <video src={episode.content_url} className="h-20" controls />
                  ) : (
                    <img src={episode.content_url} alt="Content" className="h-20" />
                  )}
                </td>
                <td className="px-6 py-4">
                  {episode.reactions?.length || 0} reactions
                  <div className="text-sm text-gray-500">
                    {episode.reactions?.map(r => r.name).join(', ')}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => appendToEpisode(episode.id)}
                    disabled={isLoading || !finalAssets}
                    className={`px-3 py-1 rounded ${
                      isLoading || !finalAssets
                        ? 'bg-gray-300 cursor-not-allowed'
                        : 'bg-blue-500 hover:bg-blue-600 text-white'
                    }`}
                  >
                    Add to Episode
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-4 rounded-lg">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
          </div>
        </div>
      )}
    </div>
  );
};