import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { auth, uploadMedaToFirebase } from '../Firebase.js'
import { useUser } from '../contexts/UserContext';
import { db } from '../Firebase.js' 
import { doc, onSnapshot, setDoc, collection } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import MenuBar from '../MenuBar'
import { getEndpoint } from '../utils';
import EpisodesManager from './EpisodesManager';

const MemeMaker = () => {
  const [docId, setDocId] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [descriptions, setDescriptions] = useState([]);
  const [selectedDescription, setSelectedDescription] = useState('');
  const [characters, setCharacters] = useState([
	'dumbledore',
	'kim_jong_un',
	'dobby',
	'putin',
	'tony_soprano',
	'lincoln',
	'charli_xcx',
	'lebron_james',
	'kamala',
	'vance',
	'trump',
	'biden',
  ]);
  const [selectedCharacter, setSelectedCharacter] = useState('');
  const [ideas, setIdeas] = useState([]);
  const [selectedIdea, setSelectedIdea] = useState('');
  const [jokes, setJokes] = useState([]);
  const [selectedJoke, setSelectedJoke] = useState('');
  const [tightenedJokes, setTightenedJokes] = useState([]);
  const [selectedTightenedJoke, setSelectedTightenedJoke] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [finalAssets, setFinalAssets] = useState(null);
  const [mimeType, setMimeType] = useState('image/png')


  // New state variables for audio management
  const [generatedAudios, setGeneratedAudios] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [status, setStatus] = useState('idle');
  const [error, setError] = useState(null);
  const [isInitializing, setIsInitializing] = useState(true);


  // Initialize new workflow
  const initializeNewWorkflow = async () => {
    setStatus('loading');
    setError(null);
    try {
      const newDocId = uuidv4();
      const docRef = doc(db, 'meme-workflows', newDocId);
      await setDoc(docRef, { 
        status: 'initialized',
        created_at: new Date().toISOString()
      });
      setDocId(newDocId);
      setStatus('ready');
    } catch (err) {
      setError('Failed to initialize new workflow');
      setStatus('error');
    }
  };


  // Initial setup
  useEffect(() => {
    const initializeWorkflow = async () => {
      setIsInitializing(true);
      if (!docId) {
        await initializeNewWorkflow();
      }
      setIsInitializing(false);
    };

    initializeWorkflow();
  }, []);

  // Subscribe to doc changes
  useEffect(() => {
  	console.log("docId changed")
    if (!docId) return;

    const docRef = doc(db, 'meme-workflows', docId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const data = doc.data();
      if (data) {
      	console.log(data.jokes)
        // Reset all state when loading existing doc
        setMediaUrl(data.mediaUrl || '');
        setDescriptions(data.descriptions || []);
        setIdeas(data.ideas || []);
        setJokes(data.jokes || []);
        setTightenedJokes(data.tightened_jokes || []);
        setGeneratedAudios(data.generated_audios || []); // New: track generated audios

        // setAudioUrls(data.audio_urls || []);
        // setSelectedDescription(data.selected_description || '');
        // setSelectedIdea(data.selected_idea || '');
        // setSelectedJoke(data.selected_joke || '');
        // setSelectedTightenedJoke(data.selected_tightened_joke || '');
        // setSelectedCharacter(data.selected_character || '');
        setFinalAssets(data.final_assets || null);
      }
    });


    return () => unsubscribe();
  }, [docId]);  

  useEffect(() => {
  	setSelectedIdea(ideas[ideas.length-1])
  }, [ideas])
  useEffect(() => {
  	setSelectedDescription(descriptions[descriptions.length-1])
  }, [descriptions])
  useEffect(() => {
  	setSelectedJoke(jokes[jokes.length-1])
  }, [jokes])
  useEffect(() => {
  	setSelectedTightenedJoke(tightenedJokes[tightenedJokes.length-1])
  }, [tightenedJokes])

  const handleFileUpload = async (event) => {
	  const file = event.target.files[0];
	  if (file) {
	    setIsLoading(true);
	    try {
	      const url = await uploadMedaToFirebase(file, docId);
	      setMediaUrl(url);
	      
	      // Update Firebase document with the new media URL
	      const docRef = doc(db, 'meme-workflows', docId);
	      await setDoc(docRef, {
	        mediaUrl: url
	      }, { merge: true });

	    } catch (error) {
	      console.error('Error uploading file:', error);
	      alert('Failed to upload file: ' + error.message);
	    } finally {
	      setIsLoading(false);
	    }
	  }
	};

  const generateDescription = async () => {
    setIsLoading(true);
    try {
      await fetch(`${getEndpoint()}/generate_description`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: mediaUrl, docId, mimeType }),
      });
    } catch (error) {
      console.error('Error generating description:', error);
    }
    setIsLoading(false);
  };

  const generateIdea = async () => {
    setIsLoading(true);
    try {
      await fetch(`${getEndpoint()}/generate_idea`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ description: selectedDescription, character: selectedCharacter, docId }),
      });
    } catch (error) {
      console.error('Error generating idea:', error);
    }
    setIsLoading(false);
  };

  const writeJoke = async () => {
    setIsLoading(true);
    try {
      await fetch(`${getEndpoint()}/write`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ image_description: selectedDescription, idea: selectedIdea, character: selectedCharacter, docId }),
      });
    } catch (error) {
      console.error('Error writing joke:', error);
    }
    setIsLoading(false);
  };

  const tightenJoke = async () => {
    setIsLoading(true);
    try {
      await fetch(`${getEndpoint()}/tighten`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ joke: selectedJoke, docId }),
      });
    } catch (error) {
      console.error('Error tightening joke:', error);
    }
    setIsLoading(false);
  };

  const generateAudio = async () => {
    if (!selectedTightenedJoke || !selectedCharacter) {
      alert('Please select a tightened joke and character first');
      return;
    }

    setIsLoading(true);
    try {
      const response = await fetch(`${getEndpoint()}/generate_audio_for_memes`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          text: selectedTightenedJoke,
          character: selectedCharacter,
          docId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to generate audio');
      }

      // The response will be handled by the Firestore listener
      // which will update generatedAudios when the new audio is ready
    } catch (error) {
      console.error('Error generating audio:', error);
      alert('Failed to generate audio: ' + error.message);
    } finally {
      setIsLoading(false);
    }
  };

 // Modified finalize function
  const finalize = async () => {
    if (!selectedAudio) {
      alert('Please select an audio version first');
      return;
    }

    setIsLoading(true);
    try {
      const docRef = doc(db, 'meme-workflows', docId);
      await setDoc(docRef, {
        selected_audio_url: selectedAudio.url,
        selected_character: selectedCharacter,
        status: 'finalizing'
      }, { merge: true });

      const response = await fetch(`${getEndpoint()}/finalize_and_upload`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          docId,
          audioUrl: selectedAudio.url,
          character: selectedCharacter
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to start finalization process');
      }

      const unsubscribe = onSnapshot(docRef, (doc) => {
        const data = doc.data();
        if (data.status === 'error') {
          alert(`Error during finalization: ${data.error_message}`);
          setIsLoading(false);
          unsubscribe();
        } else if (data.status === 'finalized') {
          setFinalAssets({
            ...data.final_assets,
            character: selectedCharacter
          });
          setIsLoading(false);
          unsubscribe();
        }
      });

    } catch (error) {
      console.error('Error finalizing:', error);
      alert(`Error during finalization: ${error.message}`);
      setIsLoading(false);
    }
  };

  // New component for audio selection
  const AudioSelector = ({ audios, selectedAudio, onSelect }) => {
    return (
      <div className="mb-4">
        <h3 className="text-lg font-semibold mb-2">Generated Audios</h3>
        <div className="space-y-2">
          {audios.map((audio, index) => (
            <div
              key={index}
              className={`border rounded p-4 ${selectedAudio?.id === audio.id ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
              onClick={() => onSelect(audio)}
            >
              <div className="flex items-center justify-between">
                <div className="flex-1">
                  <p className="text-sm text-gray-600">Generated at: {new Date(audio.timestamp).toLocaleString()}</p>
                  <audio controls className="mt-2 w-full">
                    <source src={audio.url} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                </div>
                {selectedAudio?.id === audio.id && (
                  <div className="text-blue-500 ml-4">Selected</div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // Render main component
  if (isInitializing) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500 mx-auto"></div>
          <p className="mt-4">Initializing...</p>
        </div>
      </div>
    );
  }

  console.log("mediaUrl", mediaUrl)
  const writeJokeEnabled = selectedDescription != '' && selectedIdea != '' && selectedCharacter != '';
  const generateAudioEnabled = selectedTightenedJoke !== '' && selectedCharacter !== '';
  const finalizeEnabled = selectedAudio !== null;
  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Meme Maker</h1>
      <p className='py-4'>{docId}</p>
      <DocIdSetter setDocId={setDocId} />
      
      <div className="mb-4">
        <input type="file" onChange={handleFileUpload} className="mb-2" />
        {mediaUrl && <img src={mediaUrl} alt="Uploaded media" className="max-w-xs" />}
      </div>
      <MimeTypeDropdown mimeType={mimeType} setMimeType={setMimeType} />

      <button 
			  onClick={generateDescription} 
			  className={`cursor-pointer px-4 py-2 rounded mb-4 ${mediaUrl === '' ? 'bg-gray-400' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
			  disabled={mediaUrl === ''}
			>
        Generate Description
      </button>

      <SelectableContentTable
        items={descriptions}
        selectedItem={selectedDescription}
        onSelect={setSelectedDescription}
        title="Descriptions"
        docId={docId}
        fieldName="descriptions"
      />

      <div className="mb-4">
        <select 
          value={selectedCharacter} 
          onChange={(e) => setSelectedCharacter(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select a character</option>
          {characters.map((char, index) => (
            <option key={index} value={char}>{char}</option>
          ))}
        </select>
      </div>

      <button
      	onClick={generateIdea} 
			  className={`cursor-pointer px-4 py-2 rounded mb-4 ${selectedDescription === '' ? 'bg-gray-400' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
			  disabled={selectedDescription === ''}
			>
        Generate Idea
      </button>

      <SelectableContentTable
        items={ideas}
        selectedItem={selectedIdea}
        onSelect={setSelectedIdea}
        title="Ideas"
        docId={docId}
        fieldName="ideas"
      />




      <button
      	onClick={writeJoke} 
			  className={`cursor-pointer px-4 py-2 rounded mb-4 ${!writeJokeEnabled ? 'bg-gray-400' : 'bg-blue-500 text-white hover:bg-blue-600'}`}
			  disabled={!writeJokeEnabled}
			>
        Write Joke
      </button>

      <SelectableContentTable
        items={jokes}
        selectedItem={selectedJoke}
        onSelect={setSelectedJoke}
        title="Jokes"
        docId={docId}
        fieldName="jokes"
      />

      <button onClick={tightenJoke} className="cursor-pointer bg-yellow-500 text-white px-4 py-2 rounded mb-4">
        Tighten Joke
      </button>

      <SelectableContentTable
        items={tightenedJokes}
        selectedItem={selectedTightenedJoke}
        onSelect={setSelectedTightenedJoke}
        title="Tightened Jokes"
        docId={docId}
        fieldName="tightened_jokes"
      />
      <button
        onClick={generateAudio}
        className={`cursor-pointer px-4 py-2 rounded mb-4 ${!generateAudioEnabled ? 'bg-gray-400' : 'bg-green-500 text-white hover:bg-green-600'}`}
        disabled={!generateAudioEnabled}
      >
        {`Generate Audio (${selectedCharacter})`}
      </button>

      <AudioSelector
        audios={generatedAudios}
        selectedAudio={selectedAudio}
        onSelect={setSelectedAudio}
      />
      <button onClick={finalize} className="bg-indigo-500 text-white px-4 py-2 rounded mb-4">
        Finalize and Upload
      </button>
      {isLoading && <div className="text-center">Loading...</div>}
			<div className="mt-6 mb-8 flex justify-center space-x-4">
			  {finalAssets && finalAssets.video_url && (
			    <div className="w-1/5">
			      <video 
			        key={finalAssets.video_url}  
			        controls 
			        className="w-full h-auto rounded shadow-lg"
			      >
			        <source src={finalAssets.video_url} type="video/mp4" />
			        Your browser does not support the video tag.
			      </video>
			    </div>
			  )}
			  {finalAssets && finalAssets.gif_url && (
			    <div className="w-1/5">
			      <img 
			        key={finalAssets.gif_url}  
			        src={finalAssets.gif_url} 
			        alt="GIF" 
			        className="w-full h-auto rounded shadow-lg" 
			      />
			    </div>
			  )}
			  {finalAssets && finalAssets.audio_url && (
			    <div className="w-1/5">
			      <audio 
			        key={finalAssets.audio_url}  
			        controls 
			        className="w-full rounded shadow-lg"
			      >
			        <source src={finalAssets.audio_url} type="audio/mpeg" />
			        Your browser does not support the audio element.
			      </audio>
			    </div>
			  )}
			</div>

      {/* Add the EpisodesManager component after the finalize button */}
      {finalAssets && (
        <EpisodesManager
        	characterName={selectedCharacter}
          finalAssets={finalAssets}
          mediaUrl={mediaUrl}
        />
      )}


    </div>
  );
};

export default MemeMaker;

const SelectableContentTable = ({ 
  items, 
  selectedItem, 
  onSelect, 
  title, 
  docId, 
  fieldName 
}) => {
  // Create local state to manage the content of the textareas
  const [localItems, setLocalItems] = useState(items);

  // Use useEffect to reset localItems when the items prop changes
  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const handleContentChange = (index, newContent) => {
    const updatedItems = [...localItems];
    updatedItems[index] = newContent;
    setLocalItems(updatedItems);
  };
  const handleBlur = async (index, oldContent) => {
    try {
      const docRef = doc(db, 'meme-workflows', docId);
      
      // If the edited item was selected, update the selection
      if (oldContent === selectedItem) {
        onSelect(localItems[index]);
      }

      // Update Firestore with the new array
      await setDoc(docRef, {
        [fieldName]: localItems
      }, { merge: true });

    } catch (error) {
      console.error('Error updating content:', error);
    }
  };

  console.log(fieldName, items.length)
  // return <div>{localItems}</div>
  return (
    <div className="mb-4">
      <h3 className="text-lg font-semibold mb-2">{title}</h3>
      <div className="space-y-2">
        {localItems.map((item, index) => (
          <div 
            key={index} 
            className={`border rounded p-2 ${selectedItem === item ? 'border-blue-500 bg-blue-50' : 'border-gray-300'}`}
            onClick={() => onSelect(item)}
          >
            <textarea
              value={localItems[index]}
              onChange={(e) => handleContentChange(index, e.target.value)}
              onBlur={() => handleBlur(index, item)}
              onFocus={() => onSelect(item)}
              className="w-full min-h-[100px] p-2 rounded border-none focus:ring-0 bg-transparent resize-none"
              style={{ minHeight: `${Math.min(item.split('\n').length * 24 + 24, 200)}px` }}
            />
            {selectedItem === item && (
              <div className="text-xs text-blue-500 mt-1 ml-2">
                Selected
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const DocIdSetter = ({ setDocId }) => {
  const [input, setInput] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.trim()) {
      setDocId(input.trim());
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4">
      <input
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className="border rounded px-2 py-1 mr-2"
        placeholder="Enter Doc ID"
      />
      <button type="submit" className="bg-blue-500 text-white px-4 py-1 rounded">
        Set
      </button>
    </form>
  );
};

const MimeTypeDropdown = ({mimeType, setMimeType}) => {

  // List of MIME types for the dropdown
  const mimeTypes = [
    { label: 'Image (PNG)', value: 'image/png' },
    { label: 'Image (JPEG)', value: 'image/jpeg' },
    { label: 'Video (MP4)', value: 'video/mp4' },
    { label: 'Audio (MP3)', value: 'audio/mpeg' },
    { label: 'Text (Plain)', value: 'text/plain' },
    // Add more MIME types as needed
  ];

  // Handle dropdown selection
  const handleMimeTypeChange = (e) => {
    setMimeType(e.target.value);  // Update the mimeType state
  };

  return (
<div className='my-4 flex flex-col space-y-4'>
  <label htmlFor="mime-type">Choose MIME type: </label>
  <select className='bg-gray-300 p-2 rounded' id="mime-type" value={mimeType} onChange={handleMimeTypeChange}>
    <option value="">-- Select a MIME type --</option>
    {mimeTypes.map((type) => (
      <option key={type.value} value={type.value}>
        {type.label}
      </option>
    ))}
  </select>

  <p>{mimeType || 'None'}</p>
</div>
  );
};