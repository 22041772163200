import React, { useState, useEffect } from 'react';
import { db, auth } from '../Firebase.js';
import {
    collection,
    doc,
    getDocs,
    updateDoc,
    where,
    query,
    orderBy,
    getDoc, 
    setDoc
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { getEndpoint } from '../utils';

function ShowSubmissionsViewer() {
  const [submissions, setSubmissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [showReviewed, setShowReviewed] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser && isAuthorizedUser(currentUser.email)) {
        fetchSubmissions();
      } else {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [showReviewed]);

  const isAuthorizedUser = (email) => {
    const authorizedEmails = ['smgplank@gmail.com', 'jitsvm@gmail.com'];
    return authorizedEmails.includes(email);
  };

  const handleManualCreate = (episodeId) => {
    window.open(`/text-episode-viewer/${episodeId}`, '_blank');
  };

  const renderEpisodeScript = (episode) => {
    return (
      <div className="bg-gray-100 p-4 rounded-lg mb-4">
        <div className="flex justify-between items-center mb-4">
          <h4 className="font-bold text-lg">Episode {episode.episode_number}</h4>
          <button
            onClick={() => handleManualCreate(episode.episode_id)}
            className="bg-purple-500 text-white px-4 py-2 rounded-md hover:bg-purple-600 transition duration-300"
          >
            Manually Create Episode
          </button>
        </div>
        {episode.processed_shots.map((shot, shotIndex) => (
          <div key={shotIndex} className="mb-4 bg-white p-3 rounded shadow">
            <h5 className="font-semibold text-blue-600">Shot {shotIndex + 1}</h5>
            <p className="text-sm text-gray-600">Type: {shot.type}</p>
            {shot.character && <p className="font-medium">Character: {shot.character}</p>}
            {shot.dialogue && (
              <div className="my-2 pl-4 border-l-4 border-green-500">
                <p className="italic">"{shot.dialogue}"</p>
              </div>
            )}
            {shot.action_description && (
              <div className="my-2 bg-yellow-100 p-2 rounded">
                <p className="text-sm font-medium">Action:</p>
                <p>{shot.action_description}</p>
              </div>
            )}
            {shot.set_description && (
              <div className="my-2 bg-blue-100 p-2 rounded">
                <p className="text-sm font-medium">Set:</p>
                <p>{shot.set_description}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const fetchSubmissions = async () => {
    try {
      setLoading(true);
      const submissionsRef = collection(db, 'user-show-submissions');
      const q = query(
        submissionsRef,
        where("is_reviewed", "==", showReviewed),
        where("timestamp", "!=", null),
        orderBy("timestamp", "asc")
      );
      const submissionsSnapshot = await getDocs(q);
      
      const submissionsData = submissionsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      setSubmissions(submissionsData);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching submissions:", err);
      setError("Failed to fetch submissions. Please try again.");
      setLoading(false);
    }
  };

  const handleSubmissionAction = async (submissionId, action) => {
    try {
      const submissionRef = doc(db, 'user-show-submissions', submissionId);
      const isApproving = action !== 'reject';
      
      await updateDoc(submissionRef, {
        is_reviewed: true,
        is_approved: isApproving
      });
      
      if (isApproving) {
        const submissionDoc = await getDoc(submissionRef);
        const submissionData = submissionDoc.data();
        
        if (submissionData.uid) {
          const userRef = doc(db, 'infinite-users', submissionData.uid);
          const userDoc = await getDoc(userRef);
          
          if (userDoc.exists()) {
            const userData = userDoc.data();
            let createdShows = userData.createdShows || [];
            createdShows.push(submissionData.episodes[0].episode_id);
            
            await updateDoc(userRef, { createdShows });
          } else {
            await setDoc(userRef, {
              createdShows: [submissionData.episodes[0].episode_id]
            });
          }

          const showRef = doc(db, 'infinite-users-shows', submissionData.uid);
          const showDoc = await getDoc(showRef);
          
          if (showDoc.exists()) {
            const showData = showDoc.data();
            let shows = showData.shows || [];
            shows.push(submissionData.episodes[0].episode_id);
            
            await updateDoc(showRef, { shows });
          } else {
            await setDoc(showRef, {
              createdShows: [submissionData.episodes[0].episode_id]
            });
          }
        }
        
        try {
          const requestData = {
            submission_id: submissionId,
            is_manual_render: action === 'manual'
          };
          
          const response = await axios.post(`${getEndpoint()}/render_video_episode_from_text`, requestData);
          console.log('Render video request sent:', response.data);
        } catch (renderError) {
          console.error('Error sending render video request:', renderError);
        }
      }
      
      fetchSubmissions();
    } catch (err) {
      console.error("Error updating submission status:", err);
      setError("Failed to update submission status. Please try again.");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!user || !isAuthorizedUser(user.email)) {
    return <div>Access denied. You are not authorized to view this page.</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Show Submissions</h1>
      <div className="mb-4">
        <button
          onClick={() => setShowReviewed(!showReviewed)}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Show {showReviewed ? 'Unreviewed' : 'Reviewed'} Submissions
        </button>
      </div>
      {submissions.map((submission) => (
        <div key={submission.id} className="bg-white shadow-md rounded-lg p-6 mb-6">
          <h2 className="text-xl font-semibold mb-2">{submission.title} (First Episode ID: {submission.episodes[0].episode_id})</h2>
          
          <div className="mb-4">
            <p className="text-sm text-gray-600">
              Submitted by: {submission.username || 'Anonymous'}
            </p>
            {submission.email && (
              <p className="text-sm text-gray-600">
                Email: {submission.email}
              </p>
            )}
          </div>

          <div className="flex justify-between items-center mb-4">
            <div>
              <span className={`px-2 py-1 rounded mr-2 ${submission.is_reviewed ? 'bg-green-200 text-green-800' : 'bg-yellow-200 text-yellow-800'}`}>
                {submission.is_reviewed ? 'Reviewed' : 'Not Reviewed'}
              </span>
              {submission.is_reviewed && (
                <span className={`px-2 py-1 rounded ${submission.is_approved ? 'bg-blue-200 text-blue-800' : 'bg-red-200 text-red-800'}`}>
                  {submission.is_approved ? 'Approved' : 'Not Approved'}
                </span>
              )}
            </div>
            <div className="flex flex-col items-end">
              <span className="font-semibold mb-2">Approve this show?:</span>
              <div className="flex items-center space-x-2">
                <button
                  onClick={() => handleSubmissionAction(submission.id, 'manual')}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-300"
                >
                  Yes, and I will create each episode manually
                </button>
                <div className="relative">
                  <button
                    onClick={() => handleSubmissionAction(submission.id, 'auto')}
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
                  >
                    Yes, and auto create all the episodes
                  </button>
                  <span className="absolute top-full right-0 text-xs text-red-600 mt-1 w-32 text-right">
                    Warning: Will use $20 to $50 in credits
                  </span>
                </div>
                <button
                  onClick={() => handleSubmissionAction(submission.id, 'reject')}
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition duration-300"
                >
                  No, reject it
                </button>
              </div>
            </div>
          </div>

          {submission.description && (
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Description:</h3>
              <div className="bg-gray-100 p-4 rounded-lg">
                <p className="whitespace-pre-line">{submission.description}</p>
              </div>
            </div>
          )}
  
          {submission.background_facts && (
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Background Facts:</h3>
              <div className="bg-gray-100 p-4 rounded-lg">
                <p className="whitespace-pre-line">{submission.background_facts}</p>
              </div>
            </div>
          )}
  
          {submission.characters && (
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Characters:</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {Object.entries(submission.characters).map(([name, info]) => (
                  <div key={name} className="bg-gray-100 p-4 rounded-lg">
                    <h4 className="font-medium text-lg">{name}</h4>
                    <p className="text-sm mb-1">Description: {info.description}</p>
                    <p className="text-sm mb-2">Gender: {info.gender}</p>
                    {info.reference_face_url && (
                      <img src={info.reference_face_url} alt={name} className="w-24 h-24 object-cover rounded-full mx-auto" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}

          {submission.poster_url && (
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Poster:</h3>
              <div className="bg-gray-100 p-4 rounded-lg flex justify-center">
                <img 
                  src={submission.poster_url} 
                  alt="Show Poster" 
                  className="max-w-xs h-auto rounded-lg shadow-md"
                  style={{ maxHeight: '300px' }}
                />
              </div>
            </div>
          )}
            
          {submission.episodes && submission.episodes.length > 0 && (
            <div className="mb-4">
              <h3 className="font-semibold mb-2">Episodes:</h3>
              {submission.episodes.map((episode, index) => renderEpisodeScript(episode))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ShowSubmissionsViewer;